declare var jQuery : any;
export class FormProvedorCustom {
	static messagesESP(){
		jQuery.extend(jQuery.validator.messages, {
	        required: "Este campo es obligatorio.",
	        email: "Por favor, introduce una dirección de correo electrónico válida.",
	    });
	}
	static handleCreateFormSubmit() {
		jQuery('#m_create_proveesor_from').click(function(e) {
			let form = jQuery(this).closest('form');
			form.validate({
				rules : {
					razon_social : {
						required : true
					},
					rfc : {
						required : true
					},
					direccion : {
						required : true
					},
					colonia : {
						required : true
					},
					municipio : {
						required : true
					},
					estado : {
						required : true
					},
					codigo_postal : {
						required : true
					},
					correo : {
						required : true,
						email : true
					},
					telefono_1 : {
						required : true
					},
					password : {
						required : true,
                        minlength: 8,					},

				}
			});

			if (!form.valid()) {
                e.preventDefault();
                return;
            }
		});
	}

    static handleUpdateFormSubmit(){
        jQuery('#m_update_proveesor_from').click(function(e) {
            let form = jQuery(this).closest('form');
            form.validate({
                rules : {
                    razon_social : {
                        required : true
                    },
                    rfc : {
                        required : true
                    },
                    direccion : {
                        required : true
                    },
                    colonia : {
                        required : true
                    },
                    municipio : {
                        required : true
                    },
                    estado : {
                        required : true
                    },
                    codigo_postal : {
                        required : true
                    },
                    correo : {
                        required : true,
                        email : true
                    },
                    telefono_1 : {
                        required : true
                    },
                    password : {
                        required : function () {
                            if (jQuery('#checkedPass').is(':checked')){
                            	return true;
							} else{
                            	return false;
							}
                        }
                    },
                    rppassword : {
                        required : function () {
                            if (jQuery('#checkedPass').is(':checked')){
                                return true;
                            } else{
                                return false;
                            }
                        },
                        equalTo: "#password"
                    }

                }
            });

            if (!form.valid()) {
                e.preventDefault();
                return;
            }
        });
	}

	static init(){
		FormProvedorCustom.messagesESP();
		FormProvedorCustom.handleCreateFormSubmit();
        FormProvedorCustom.handleUpdateFormSubmit();
	}
}
